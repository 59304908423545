






































































































































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { NpcStore } from '@/store'

export default Vue.extend({
  name: 'combined-print',
  components: {},
  props: {
    npcId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    npc: null,
    blank: false,
  }),
  created() {
    if (this.npcId === 'blank') this.blank = true
    this.npc = getModule(NpcStore, this.$store).Npcs.find(npc => npc.ID === this.npcId)
  },
})
